<template>
  <a
    class="img--btn"
    :class="[className]"
    @click="onclick"
    v-if="cardsStatus || !type"
  >
    <div class="row">
      <div class="col-7">
        <p class="img--btn__type body-large">
          {{ $t("home.pageBtn." + className + ".type") }}
        </p>
        <h3 class="img--btn__text fw-bold">
          {{ $t("home.pageBtn." + className + ".text") }}
        </h3>
      </div>

      <div class="col-5">
        <div class="img--btn__imgs">
          <img :src="imgs.static" alt="" class="img--btn__imgs__static" />
          <img
            :src="imgs.withAnimation"
            alt=""
            class="img--btn__imgs__with--animation"
          />
        </div>
      </div>
    </div>
  </a>

  <a class="img-mobile-btn" v-if="!cardsStatus && type" @click="onclick">
    <img :src="mobileImg" />
  </a>
</template>

<script>
export default {
  name: "imgBtn",
  props: {
    className: { type: String },
    imgs: { type: Object },
    link: { type: String },
    cardsStatus: { type: Boolean },
    type: { type: String },
    mobileImg: { type: String },
  },
  emits: ["clickImgBtn"],
  methods: {
    onclick() {
      if (this.link) this.$router.push(`${this.link}`);
      this.$emit("clickImgBtn");
    },
  },
};
</script>

<style lang="scss">
@import "~/assets/style/include/_commonInclude.scss";
.img--btn {
  background: color(text-black);
  display: block;
  border-radius: rem(8);
  padding: rem(10) rem(16);
  text-decoration: none;
  color: color(white);
  overflow: hidden;
  min-height: rem(78);
  cursor: pointer;
  position: relative;

  &:hover {
    color: color(white);
    text-decoration: none;
  }

  &__imgs {
    position: absolute;
    transform: translate(-50%, -50%);

    &__static {
      position: relative;
    }

    &__with--animation {
      position: absolute;
    }
  }

  &.music {
    background-color: #6baec2;
    background-image: url("/images/pageBtn/page_btn_music.png");
    background-repeat: no-repeat;
    background-position: right 0%;
    background-size: cover;
    color: color(beige);

    .img--btn {
      &__imgs {
        right: -9.5%;
        top: 57%;

        &__static {
          width: rem(94);
        }

        &__with--animation {
          width: 40%;
          right: 95%;
          top: 4%;
          animation: rotate20 2s linear infinite alternate;
        }
      }
    }
  }

  &.water {
    background-color: #fdf4d7;
    background-image: url("/images/pageBtn/page_btn_water.png");
    background-repeat: no-repeat;
    background-position: right 0%;
    background-size: cover;
    color: color(blue);

    .img--btn {
      &__imgs {
        right: -9.5%;
        top: 57%;

        &__static {
          width: rem(94);
        }

        &__with--animation {
          width: 40%;
          right: 95%;
          top: 4%;
          animation: rotate20 2s linear infinite alternate;
        }
      }
    }
  }

  &.stretch {
    background: color(light-blue);

    .img--btn {
      &__imgs {
        right: -5%;
        bottom: 0;
        transform: translate(-50%, 0);

        &__with--animation {
          width: 78%;
          top: 3%;
          right: -66%;
          animation: rotate360 10s linear infinite;
        }

        &__static {
          width: rem(105);
          z-index: 2;
        }
      }
    }
  }

  &.breath {
    background: color(balance-yellow);
    color: color(blue);

    .img--btn {
      &__imgs {
        right: -7%;
        top: 50%;
        &__static {
          width: rem(102);
        }

        &__with--animation {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 85%;
          animation: upAndDown 2s linear infinite alternate;
        }
      }
    }
  }

  &.adventure {
    position: relative;
    background: color(blue-transparent);

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      background-image: url("/images/pageBtn/page_btn_grass.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .img--btn {
      &__imgs {
        transform: unset;
        right: 0;
        &__static {
          width: rem(134);
          right: 0;
          bottom: rem(5);
        }
      }

      &__type,
      &__text {
        position: relative;
      }
    }
  }
}

.img-mobile-btn {
  display: flex;
  flex-direction: column;
  text-align: center;
  text-decoration: none;
  color: color(text-black) !important;
  font-size: rem(8);
  font-weight: 500;
  row-gap: rem(4);
}
</style>
