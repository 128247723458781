<template>
  <div class="header">
    <div class="ly--content--container">
      <div class="header__wrapper">
        <img class="header__logo" src="~//images/common/logo_8alance.png" />

        <div class="header__icon" @click="closeWebView">
          <img class="header__icon__cross" src="~//images/svg/icon_cross.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { postMessage } from "~/src/utils/";
import { mapWritableState } from "pinia";
import { useCommonStore } from "~/src/stores/common";

export default {
  name: "pageHeader",
  data() {
    return {
      displayDebug: false,
      showTokenPopup: false,
    };
  },
  computed: {
    ...mapWritableState(useCommonStore, ["showErrorPopup"]),
  },
  methods: {
    closeWebView() {
      if (process.client) {
        postMessage({
          type: "NAVIGATE_BACK",
        })
          .then(() => {})
          .catch((error) => {
            console.log("closeWebView error: ", error);
            this.showErrorPopup = true;
          });
      }
    },
  },
  watch: {},
};
</script>

<style lang="scss">
@import "~/assets/style/include/_commonInclude.scss";

.header {
  position: fixed;
  top: 0;
  width: 100vw;
  background: color(beige);
  z-index: 99;
  padding-top: var(--inset-top);

  &__debug {
    width: 180px;
    flex: 0 0 auto;
    margin-left: 20px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(16) 0;
  }

  &__logo {
    height: rem(42);
  }

  &__icon {
    &__cross {
      cursor: pointer;
      height: rem(24);
    }
  }
}
</style>
