<template>
  <div class="img--wrapper">
    <div :class="[type ? '' : 'ly--content--container']">
      <img-btn
        v-for="(value, key, index) in newPageBtn"
        :key="index"
        :className="value.className"
        :imgs="value.imgs"
        :mobileImg="value.mobileImg"
        :link="value.link"
        :cardsStatus="cardsStatus"
        :type="type"
        @clickImgBtn="handleClick(value)"
      ></img-btn>
    </div>
  </div>
</template>

<script>
import { imgBtn } from "~/components/common";
import { postMessage } from "~/src/utils/";
import { mapWritableState } from "pinia";
import { useCommonStore } from "~/src/stores/common";

export default {
  name: "imgBtnWrapper",
  props: {
    gender: { type: String },
    user: { type: Object },
    type: { type: String },
    cardsStatus: { type: Boolean },
  },
  data() {
    return {
      homePageBtn: ["adventure", "breath", "stretch"],
      pageBtn: {
        adventure: {
          className: "adventure",
          imgs: {
            static: "/images/pageBtn/page_btn_b_doll.png",
            withAnimation: "",
          },
          link: null,
          postMsgFunc: true,
        },
        breath: {
          className: "breath",
          imgs: {
            static: "",
            withAnimation: "",
          },
          link: "",
          mobileImg: "",
          postMsgFunc: false,
        },
        stretch: {
          className: "stretch",
          imgs: {
            static: "",
            withAnimation: "/images/pageBtn/page_btn_stretch_balls.png",
          },
          link: "",
          mobileImg: "",
          postMsgFunc: false,
        },
        water: {
          className: "water",
          imgs: {
            static: "",
            withAnimation: "",
          },
          link: "#",
          postMsgFunc: false,
        },
        music: {
          className: "music",
          imgs: {
            static: "",
            withAnimation: "",
          },
          link: "#",
          postMsgFunc: false,
        },
      },
      lang: "",
    };
  },
  components: {
    imgBtn,
  },
  created() {
    this.lang = this.$i18n.locale;
    this.updatePageBtn();
  },
  mounted() {
    console.log(this.pageBtn);
  },
  computed: {
    ...mapWritableState(useCommonStore, ["showErrorPopup"]),

    langPath() {
      return this.lang == "en" ? "/" : "/tc/";
    },
    testingArticleBtn() {
      if (!this.langPath || !this.user) {
        return;
      }

      if (
        !this.user.Name.includes("Ginny") &&
        !this.user.Name.includes("Bill")
      ) {
        return;
      }

      return {
        className: "article",
        imgs: {
          static: "/images/pageBtn/page_btn_article.png",
          withAnimation: "/images/pageBtn/page_btn_questions.png",
        },
        link: this.langPath + "articles",
      };
    },
    testingBtn() {
      if (!this.langPath || !this.user) {
        return;
      }

      if (
        !this.user.Name.includes("Ginny") &&
        !this.user.Name.includes("Bill")
      ) {
        return;
      }

      return {
        className: "stretch",
        imgs: {
          static: "/images/pageBtn/page_btn_stretch_boy.png",
          withAnimation: "/images/pageBtn/page_btn_stretch_balls.png",
        },
        link: this.langPath + "stretchTest",
      };
    },
    newPageBtn() {
      // return this.pageBtn;
      const pageBtnArray = Object.values(this.pageBtn);
      const filteredPageBtn = pageBtnArray.filter((btn) => {
        if (this.type) {
          return this.type.includes(btn.className);
        } else {
          // hide adventure btn if user is not testing account or name includes "Tester" and "Terry"
          // const isTestingAccount =
          //   this.user.Name.includes("Ginny") ||
          //   this.user.Name.includes("Bill") ||
          //   this.user.Name.includes("Tester") ||
          //   this.user.Name.includes("Terry") ||
          //   this.user.Name.includes("Wendy Sin") ||
          //   this.user.Name.includes("Ivy Kong") ||
          //   this.user.Name.includes("Tony Fung") ||
          //   this.user.Name.includes("Naomi Cheung");
          // if (btn.className == "adventure" && !isTestingAccount) {
          //   return false;
          // }

          return this.homePageBtn.includes(btn.className);
        }
      });

      return filteredPageBtn;
    },
  },
  methods: {
    updatePageBtn() {
      const gender = this.gender == "M" ? "boy" : "girl";
      const genderChar = this.gender.toLowerCase();

      //set stretch game btn info
      this.pageBtn.stretch.imgs.static =
        "/images/pageBtn/page_btn_stretch_" + gender + ".png";
      this.pageBtn.stretch.mobileImg =
        "/images/pageBtn/img_event_deskercise_" +
        genderChar +
        "_" +
        this.lang +
        ".png";
      this.pageBtn.stretch.link = this.langPath + "stretch";

      //set breathing game btn info
      this.pageBtn.breath.imgs.static =
        "/images/pageBtn/page_btn_" + gender + "_breathe.png";
      this.pageBtn.breath.imgs.withAnimation =
        "/images/pageBtn/page_btn_breathing_" + gender + ".png";
      this.pageBtn.breath.mobileImg =
        "/images/pageBtn/img_event_breating_" +
        genderChar +
        "_" +
        this.lang +
        ".png";
      this.pageBtn.breath.link = this.langPath + "breathing";

      // set article & quiz btn info
      // this.pageBtn.article.link = this.langPath + "articles";

      this.pageBtn.music.link = this.langPath + "home#music";
      this.pageBtn.music.mobileImg =
        "/images/pageBtn/img_event_playlist_" + this.lang + ".png";
      this.pageBtn.water.link = this.langPath + "home#water";
      this.pageBtn.water.mobileImg =
        "/images/pageBtn/img_event_hydrated_" + this.lang + ".png";
    },
    handleClick(btnSpec) {
      if (btnSpec.postMsgFunc) {
        postMessage({
          type: "NAVIGATE",
          params: {
            screen: "AdventureHunt",
          },
        })
          .then(() => {})
          .catch((error) => {
            console.log("AdventureHunt direct error: ", error);
            this.showErrorPopup = true;
          });
      }
    },
  },
  watch: {
    gender() {
      this.updatePageBtn();
    },
  },
};
</script>

<style lang="scss">
@import "~/assets/style/include/_commonInclude.scss";
.img--wrapper {
  .ly--content--container {
    background: color(balance-green);
    padding-bottom: rem(16);
  }

  .img--btn {
    margin-bottom: rem(16);

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
